@import url(https://fonts.googleapis.com/css?family=Fjalla+One);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: whitesmoke;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body{
  height: 100%;
}

#root{
  height: 100%;
 
}
@media only screen and (max-width: 950px) {
  body, #root {
    background-color: white;
  }
  
}
a {
  text-decoration: none;
  color: #039BE7;
}

a:hover {
  color: #49c2ff;
}

.divider-margin {
  margin: 10px 0 !important;
}

.settings-table {
  width: 100%;
}

.settings-table th, .settings-table td {
  padding: 10px;
}

.settings-table th {
  text-align: left;
}

.settings-table td {
  text-align: right;
}

/* Geosuggest styles */
.geosuggest {
  font-size: 18px;
  font-size: 1rem;
  position: relative;
  width: 100%;
  margin: 1em auto;
  text-align: left;
}
.geosuggest__input {
  width: 100%;
  border: 2px solid transparent;
  box-shadow: 0 0 1px #3d464d;
  padding: .5em 1em;
  -webkit-transition: border 0.2s, box-shadow 0.2s;
          transition: border 0.2s, box-shadow 0.2s;
}
.geosuggest__input:focus {
  border-color: #267dc0;
  box-shadow: 0 0 0 transparent;
}
.geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: -1px;
  background: #fff;
  border: 2px solid #267dc0;
  border-top-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  -webkit-transition: max-height 0.2s, border 0.2s;
          transition: max-height 0.2s, border 0.2s;
}
.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

/**
 * A geosuggest item
 */
.geosuggest__item {
  font-size: 18px;
  font-size: 1rem;
  padding: .5em .65em;
  cursor: pointer;
}
.geosuggest__item:hover,
.geosuggest__item:focus {
  background: #f5f5f5;
}
.geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}
.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
  background: #ccc;
}
.geosuggest__item__matched-text {
  font-weight: bold;
}

/* stripe styles */
.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #d6d6d6;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #a5acb3;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
/* body {
    margin: 0px;
  }
  
  #map {
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  
  #mapSearchContainer {
    position: fixed;
    top: 20px;
    right: 40px;
    height: 30px;
    width: 180px;
    z-index: 110;
    font-size: 10pt;
    color: #5d5d5d;
    border: solid 1px #bbb;
    background-color: #f8f8f8;
  }
  
  .pointer {
    position: absolute;
    top: 86px;
    left: 60px;
    z-index: 99999;
  }
  
  .geocoder-control-input {
    background-image: url("https://raw.githubusercontent.com/Esri/esri-leaflet-geocoder/master/src/img/search.png");
  }
   */
/* .map-surface .geosearch
  {
      visibility: visible !important;
  }
  .map-surface {
    height: 400px;
    width: 100%;
    visibility: hidden;
  } */
.description-a:focus {
  height: 150px !important;
  width: 50px !important;
  color: red !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
#seeMore {
  cursor: pointer;
  /* color: lightblue; */
}
.wrap {
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* Opera <7 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* IE */
}
pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.credit-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 350px;
    height: 200px;
    padding: 25px;
    border-radius: 15px;
    color: white;
    background-image: linear-gradient(25deg, #0f509e, #1399cd);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.credit-card__number {
    display: flex;
    justify-content: center;
    font-family: 'Fjalla One', sans-serif;
    font-size: 35px;
}

.credit-card__info {
    display: flex;
    justify-content: space-between;

    font-family: 'Fjalla One', sans-serif;
    font-size: 15px;
}

.credit-card__info_label {
    font-size: 14px;
}

.credit-card__info_expiry {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.credit-card__logo {
  font-size: 20px;
}
.address-elipse {
  display: flex;
  white-space: wrap;
  flex-wrap: wrap;
  /* text-overflow: ellipsis; */
  overflow: hidden;
  width: 200px;
  max-width: 200px;
  cursor: copy;
  word-wrap: break-word;
  display: inline-block;
}
.other-fields {
  display: flex;
  white-space: wrap;
  flex-wrap: wrap;
  overflow: hidden;
  width: 150px;
  max-width: 150px;
}

@media (min-width: 1500px) {
  .address-elipse {
    width: auto !important;
    max-width: auto !important;
  }
  .other-fields {
    width: auto !important;
    max-width: auto !important;
  }
}

.address-elipse {
  display: flex;
  white-space: wrap;
  flex-wrap: wrap;
  /* text-overflow: ellipsis; */
  overflow: hidden;
  width: 200px;
  max-width: 200px;
  cursor: copy;
  word-wrap: break-word;
  display: inline-block;
}
.other-fields {
  display: flex;
  white-space: wrap;
  flex-wrap: wrap;
  overflow: hidden;
  width: 150px;
  max-width: 150px;
}
.num-fields {
  display: flex;
  white-space: wrap;
  flex-wrap: wrap;
  overflow: hidden;
  width: 100px;
  max-width: 100px;
}
@media (min-width: 1500px) {
  .address-elipse {
    width: auto !important;
    max-width: auto !important;
  }
  .other-fields {
    width: auto !important;
    max-width: auto !important;
  }
}

