/* body {
    margin: 0px;
  }
  
  #map {
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  
  #mapSearchContainer {
    position: fixed;
    top: 20px;
    right: 40px;
    height: 30px;
    width: 180px;
    z-index: 110;
    font-size: 10pt;
    color: #5d5d5d;
    border: solid 1px #bbb;
    background-color: #f8f8f8;
  }
  
  .pointer {
    position: absolute;
    top: 86px;
    left: 60px;
    z-index: 99999;
  }
  
  .geocoder-control-input {
    background-image: url("https://raw.githubusercontent.com/Esri/esri-leaflet-geocoder/master/src/img/search.png");
  }
   */
/* .map-surface .geosearch
  {
      visibility: visible !important;
  }
  .map-surface {
    height: 400px;
    width: 100%;
    visibility: hidden;
  } */
.description-a:focus {
  height: 150px !important;
  width: 50px !important;
  color: red !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
#seeMore {
  cursor: pointer;
  /* color: lightblue; */
}
.wrap {
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* Opera <7 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* IE */
}
pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
