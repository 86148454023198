.address-elipse {
  display: flex;
  white-space: wrap;
  flex-wrap: wrap;
  /* text-overflow: ellipsis; */
  overflow: hidden;
  width: 200px;
  max-width: 200px;
  cursor: copy;
  word-wrap: break-word;
  display: inline-block;
}
.other-fields {
  display: flex;
  white-space: wrap;
  flex-wrap: wrap;
  overflow: hidden;
  width: 150px;
  max-width: 150px;
}
.num-fields {
  display: flex;
  white-space: wrap;
  flex-wrap: wrap;
  overflow: hidden;
  width: 100px;
  max-width: 100px;
}
@media (min-width: 1500px) {
  .address-elipse {
    width: auto !important;
    max-width: auto !important;
  }
  .other-fields {
    width: auto !important;
    max-width: auto !important;
  }
}
