@import url('https://fonts.googleapis.com/css?family=Fjalla+One');

.credit-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 350px;
    height: 200px;
    padding: 25px;
    border-radius: 15px;
    color: white;
    background-image: linear-gradient(25deg, #0f509e, #1399cd);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.credit-card__number {
    display: flex;
    justify-content: center;
    font-family: 'Fjalla One', sans-serif;
    font-size: 35px;
}

.credit-card__info {
    display: flex;
    justify-content: space-between;

    font-family: 'Fjalla One', sans-serif;
    font-size: 15px;
}

.credit-card__info_label {
    font-size: 14px;
}

.credit-card__info_expiry {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.credit-card__logo {
  font-size: 20px;
}